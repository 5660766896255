.toast-container {
  font-family: "ABBvoice-Regular" !important;
}

.toast-container.Toastify__toast {
  background-color: var(--grey-20) !important;
  color: black !important;
}
.Toastify__close-button {
  color: black !important;
}
